<template>
  <b-container :show="$apollo.loading" rounded="sm" variant="dark">
    <modal-incompatible-item
      v-if="currentItemObject && currentItemObject.incompatibleItem
        && userObject && !userObject.isSysAdmin"
      :item-name="currentItemObject.parentProjectName"
      :modal-for-detail-page="true"
    ></modal-incompatible-item>
    <b-tabs
      v-else-if="!$apollo.loading && currentItemObject && $hasPrivileges(
          $privileges('CAN_EDIT_PAGE_SETTINGS'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
      vertical
      nav-wrapper-class="col-3"
      nav-class="mt-5"
      v-model="tabIndex"
    >
      <template #tabs-start>
        <h3>{{ $t('settings.settings') }}</h3>
      </template>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_PAGE_SETTINGS_PAGE'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        @click="setUrl('')"
      >
        <template v-slot:title>
          {{ $t('settings.page.tabs.pageName') }}
        </template>
        <page-settings-page-description
          v-if="userObject && pageSettings"
          class="mt-5"
          :page-data="pageData"
          :page-tags="pageTags"
          :sibling-slugs="allChildSlugsOfParentFolder"
          :all-tags="allTagsOfGroup"
          :has-parent-folder="hasParentFolder"
          :is-sys-admin="userObject.isSysAdmin"
          @updatePageSettings="updatePageDescriptionSettings"
        ></page-settings-page-description>
      </b-tab>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_PAGE_SETTINGS_SEO'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        :ref="$t('settings.page.tabs.seoHash')"
        @click="setUrl($t('settings.page.tabs.seoHash'))"
      >
        <template v-slot:title>
          {{ $t('settings.page.tabs.seoName') }}
        </template>
        <page-settings-seo
          class="mt-5"
          :seo-data="seoData"
          @updateSOSettings="updateSeoSettings"
        ></page-settings-seo>
      </b-tab>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_PAGE_SETTINGS_SEO'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
      >
        <template v-slot:title>
          {{ $t('settings.page.tabs.contextName') }}
        </template>
        <page-settings-context
          v-if="personalizationData"
          class="mt-5"
          :context-data="contextData"
          :personalization-data="personalizationData"
          :personalization-set-data="personalizationSetData"
          :personalization-values="personalizationValues"
          @context-create="createContext"
          @context-update="updateContext"
          @context-copy="copyContext"
          @remove-element="removeElement"
          @remove-context="removeContext"
        ></page-settings-context>
      </b-tab>
    </b-tabs>
    <modal-leave-page ref="leaveModalWindow"/>
  </b-container>
</template>

<script>
import SetRunBreadcrumbQueryInCache from '@/graphQlQueries/mutations/setRunBreadcrumbQueryInCache';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import DeleteTag from '@/graphQlQueries/mutations/deleteTag';
import DeletePageTag from '@/graphQlQueries/mutations/deletePageTag';
import InsertTag from '@/graphQlQueries/mutations/insertTag';
import InsertPageTag from '@/graphQlQueries/mutations/insertPageTag';
import GetAllItemsOfFolderById from '@/graphQlQueries/queries/getAllItemsOfFolderById';
import GetAllItemsOfProjectById from '@/graphQlQueries/queries/getAllItemsOfProjectById';
import GetDataHasChangedForSavingFromCache from '@/graphQlQueries/queries/getDataHasChangedForSavingFromCache';
import GetGroupSlugByGroupId from '@/graphQlQueries/queries/getGroupSlugByGroupId';
import GetPageSettingsById from '@/graphQlQueries/queries/getPageSettingsById';
import SetPageSettings from '@/graphQlQueries/mutations/setPageSettings';
import GetLanguageById from '@/graphQlQueries/queries/getLanguageById';
import GetAllTagsOfGroup from '@/graphQlQueries/queries/getAllTagsOfGroup';
import GetAllPageAndProjectIdsByTagId from '@/graphQlQueries/queries/getAllPageAndProjectIdsByTagId';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';
import GetPersonalizedVariableSetsItems from '@/graphQlQueries/queries/getPersonalizedVariableSetsItems';
import BeforeLeavingRouteOpenModal from '@/mixins/beforeLeavingRouteOpenModal';
import GetItemFromUrl from '@/mixins/getItemFromUrl';
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import CheckAccessForTeamUser from '@/mixins/checkAccessForTeamUser';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import SetDataChangedInCache from '@/mixins/setDataChangedInCache';
import { isEqual, cloneDeep } from 'lodash';

const equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  // eslint-disable-next-line no-restricted-syntax
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

export default {
  name: 'PageSettings',
  mixins: [
    BeforeLeavingRouteOpenModal,
    UserDataAndAccess,
    GetItemFromUrl,
    CheckAccessForTeamUser,
    SetDataChangedInCache,
    SendPublishedDataToServer,
  ],
  components: {
    ModalIncompatibleItem: () => import('@/components/modals/ModalIncompatibleItem.vue'),
    ModalLeavePage: () => import('@/components/modals/ModalLeavePage.vue'),
    PageSettingsPageDescription: () => import('@/components/settings/page/PageSettingsPageDescription.vue'),
    PageSettingsSeo: () => import('@/components/settings/page/PageSettingsSeo.vue'),
    PageSettingsContext: () => import('@/components/settings/page/PageSettingsContext.vue'),
  },
  // We need to activate the tab here because DOM elements are not rendered in 'created()'
  async mounted() {
    // This is to remove the '#' from the string
    const hash = this.$route.hash.substr(1);
    if (hash === this.$t('settings.page.tabs.seoHash')) {
      // eslint-disable-next-line max-len
      // interval here is needed as we need to wait for the sub component 'b-tab' to finish rendering
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.page.tabs.seoHash')]) {
          this.$refs[this.$t('settings.page.tabs.seoHash')].activate();
          clearInterval(interval);
        }
      }, 50);
    }
  },
  data: () => ({
    pageType: 'page-settings',
    pageSettingsQuery: null,
    pageSettings: null,
    itemPath: null,
    pageData: null,
    pageName: null,
    seoData: null,
    pageDataOriginal: null,
    seoDataOriginal: null,
    corporateIdentity: null,
    oldSlug: null,
    isPublished: null,
    typeOfParentItem: null,
    parentIdOfItem: null,
    allTagsOfGroup: null,
    allTagsOfGroupWithId: [],
    pageTags: [],
    pageTagsOriginal: [],
    groupSlug: null,
    groupDomain: null,
    tabIndex: 0,
    hasParentFolder: null,
    personalizationData: null,
    personalizationSetData: null,
    personalizationValues: null,
    context: [],
    contextOriginal: [],
  }),
  apollo: {
    personalizationData: {
      query: GetPersonalizedVariableSetsItems,
      variables() {
        return {
          itemPath: this.itemPath,
        };
      },
      update(data) {
        const personalizedSchema = [];
        const personalizationSetData = [];
        const personalizationData = [
          { value: null, text: this.$t('settings.page.context.selectPersonalizationValue') },
        ];
        if (data.getPersonalizedVariableSetsByPath.length) {
          data.getPersonalizedVariableSetsByPath.forEach((item) => {
            const personalizedSchemaItem = {
              setId: item.personalized_variable_set_id,
              name: item.name,
              values: [],
            };
            const result = personalizationSetData.find(
              (setObject) => setObject.value === item.personalized_variable_set_id,
            );
            if (!result) {
              personalizationSetData.push(
                { value: item.personalized_variable_set_id, text: `${item.name}` },
              );
            }
            if (item.schema) {
              item.schema.forEach((variable) => {
                personalizationData.push({ value: variable.id, text: variable.name });
                personalizedSchemaItem.values.push({ id: variable.id, name: variable.name });
              });
            }
            personalizedSchema.push(personalizedSchemaItem);
          });
        }
        // sort the personalizationSetData by name
        personalizationSetData.sort((a, b) => a.text.localeCompare(b.text));
        const personalizationSetDataSorted = [
          { value: null, text: this.$t('settings.page.context.selectPersonalizationSet') },
          ...personalizationSetData,
        ];
        this.personalizationSetData = personalizationSetDataSorted;
        this.personalizationValues = personalizedSchema;
        return personalizationData;
      },
      skip() {
        return !this.itemPath;
      },
      fetchPolicy: 'network-only',
    },
    groupSlug: {
      query: GetGroupSlugByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        if (data.groups.length) {
          this.groupDomain = data.groups[0].domain;
          return data.groups[0].slug;
        }
        return null;
      },
      skip() {
        return !this.groupId;
      },
    },
    allChildSlugsOfParentFolder: {
      query() {
        if (this.typeOfParentItem === 'folder') {
          return GetAllItemsOfFolderById;
        }
        return GetAllItemsOfProjectById;
      },
      variables() {
        return {
          id: this.parentIdOfItem,
        };
      },
      update(data) {
        // first get all slugs of folders
        const slugsOfAllChildItems = [];
        if (data.hasOwnProperty('folders')) {
          data.folders.forEach((parentFolder) => {
            const childItems = ['child_folders', 'child_projects', 'child_pages'];
            childItems.forEach((childItem) => {
              if (parentFolder[childItem]) {
                parentFolder[childItem].forEach((siblingFolder) => {
                  if (this.oldSlug !== siblingFolder.slug) {
                    slugsOfAllChildItems.push(siblingFolder.slug);
                  }
                });
              }
            });
          });
        }
        if (data.hasOwnProperty('projects')) {
          data.projects.forEach((parentProject) => {
            parentProject.child_pages.forEach((childPage) => {
              if (this.oldSlug !== childPage.slug) {
                slugsOfAllChildItems.push(childPage.slug);
              }
            });
          });
        }
        return slugsOfAllChildItems;
      },
      skip() {
        return !this.parentIdOfItem || !this.typeOfParentItem || !this.oldSlug;
      },
      fetchPolicy: 'network-only',
    },
    allTagsOfGroup: {
      query: GetAllTagsOfGroup,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        const allTags = [];
        this.allTagsOfGroupWithId = [];
        data.tags.forEach((tagItem) => {
          if (!allTags.includes(tagItem.name)) {
            allTags.push(tagItem.name);
            this.allTagsOfGroupWithId.push({ tagId: tagItem.tag_id, name: tagItem.name });
          }
        });
        // save all the tags with the corresponding id for saving it later
        return allTags;
      },
      skip() {
        return !this.groupId;
      },
      fetchPolicy: 'network-only',
    },
    numberOfTagsUsedInGroup: {
      query: GetAllPageAndProjectIdsByTagId,
      variables() {
        // use a random number initialy because we will refetch the query later
        return {
          tagId: 3,
        };
      },
      update(data) {
        return data;
      },
    },
    pageSettings: {
      query: GetPageSettingsById,
      variables() {
        return {
          id: this.currentItemObject.id,
        };
      },
      update(data) {
        // set up the page data
        if (data.pages_by_pk) {
          this.itemPath = data.pages_by_pk.item_path;
          this.typeOfParentItem = (data.pages_by_pk.parent_folder_id)
            ? 'folder'
            : 'project';
          this.parentIdOfItem = (data.pages_by_pk.parent_folder_id)
            ? data.pages_by_pk.parent_folder_id
            : data.pages_by_pk.parent_project_id;
          this.hasParentFolder = !!(data.pages_by_pk.parent_folder_id);
          const settings = { ...data.pages_by_pk };
          this.seoData = {
            og_title: settings.og_title,
            og_description: settings.og_description,
            canonical_url: settings.canonical_url,
            hreflang: settings.hreflang,
            og_images: settings.og_images,
            language_id: settings.language_id,
          };
          if (settings.parent_folder && settings.parent_folder.corporate_identity) {
            this.corporateIdentity = settings.parent_folder.corporate_identity;
          } else if (settings.parent_project && settings.parent_project.corporate_identity) {
            this.corporateIdentity = settings.parent_project.corporate_identity;
          } else {
            this.corporateIdentity = null;
          }
          this.context = (settings.context_settings)
            ? cloneDeep(settings.context_settings)
            : [];
          this.contextOriginal = cloneDeep(this.context);
          this.seoDataOriginal = { ...this.seoData };
          this.pageData = {
            name: settings.name,
            description: settings.description,
            slug: settings.slug,
            keywords: settings.keywords,
            featured: settings.featured,
            listed: settings.listed,
            published: settings.published,
            searchable: settings.searchable,
            thumbnails: settings.thumbnails,
            is_shared_template: settings.is_shared_template,
          };
          this.pageDataOriginal = { ...this.pageData };
          this.oldSlug = settings.slug;
          this.addTagsInArray(settings.pages_tags);
          this.pageName = settings.name;
          this.isPublished = settings.published;
          return data.pages_by_pk;
        }
        return null;
      },
      skip() {
        return !this.currentItemObject || (this.currentItemObject && this.currentItemObject.type !== 'page');
      },
      fetchPolicy: 'network-only',
    },
    hasDataForSavingChangedObject: {
      query: GetDataHasChangedForSavingFromCache,
      update(data) {
        if (data.dataHasChangedForSaving) {
          if (data.dataHasChangedForSaving.isSubmittedButtonPressed) {
            // set allowQueryOfPublishData so that the publish Query is triggered
            this.updatePageSettings();
          } else if (data.dataHasChangedForSaving.isCancelButtonPressed) {
            this.resetSettings();
          }
        }
        return data;
      },
    },
  },
  computed: {
    contextData: {
      cache: false,
      get() {
        console.log('context', this.context);
        return this.context;
      },
    },
  },
  methods: {
    createContext(newContext) {
      this.context.push({
        event: 'onFetchMagazine',
        source: newContext.source,
        action: newContext.action,
        cases: {
          or: [],
        },
      });
      this.checkForChangedContext();
    },
    removeContext(contextIndex) {
      this.context.splice(contextIndex, 1);
      this.checkForChangedContext();
    },
    removeElement(element) {
      this.context[element.action].cases.or[element.or].and.splice(element.and, 1);
      if (this.context[element.action].cases.or[element.or].and.length === 0) {
        this.context[element.action].cases.or.splice(element.or, 1);
        if (this.context[element.action].cases.or.length < 1) {
          this.context.splice(this.context[element.action].cases.or[element.or], 1);
        }
      }
      this.checkForChangedContext();
    },
    copyContext(context) {
      this.context = cloneDeep(context);
      this.checkForChangedContext();
    },
    updateContext(newContext) {
      const contextIndex = this.context.findIndex(
        (context) => context.action === newContext.action,
      );
      const { orIndex } = newContext;
      const operation = {};
      operation[newContext.operator] = [
        { var: newContext.leftValue },
        newContext.rightValue,
      ];
      if (orIndex === undefined) {
        const contextItem = {
          and: [],
        };
        contextItem.and.push(operation);
        // add the item to the end of the relevant context
        this.context[contextIndex].cases.or.push(contextItem);
      } else {
        // and context inside the or context
        this.context[contextIndex].cases.or[orIndex].and.push(operation);
      }
      this.checkForChangedContext();
    },
    checkForChangedContext() {
      if (!isEqual(this.context, this.contextOriginal)) {
        this.pageSettingsChanged(true);
      }
    },
    setUrl(hash) {
      const regex = /#.*$/ig;
      let url = '';
      if (regex.test(this.$route.fullPath)) {
        url = this.$route.fullPath.replace(regex, (hash !== '') ? `#${hash}` : '');
      } else {
        url = this.$route.fullPath.concat((hash !== '') ? `#${hash}` : '');
      }
      window.history.pushState({}, '', url);
    },
    addTagsInArray(rawTags) {
      const tagArray = [];
      rawTags.forEach((tagItem) => {
        tagArray.push(tagItem.tag.name);
      });
      this.pageTags = [...tagArray];
      this.pageTagsOriginal = [...tagArray];
    },
    showLeaveModal() {
      this.$refs.leaveModalWindow.$refs.leaveModal.show();
    },
    resetCacheForHeaderButtons() {
      this.setDataChangedInCache({
        pageType: this.pageType,
        isDataChanged: false,
        isSubmittedButtonPressed: false,
        isCancelButtonPressed: false,
        isDataPublished: this.isPublished,
      });
      this.$apollo.queries.pageSettings.refetch();
    },
    async updatePageSettings() {
      try {
        const pageId = this.currentItemObject.id;
        const {
          seoData, pageData, oldSlug,
        } = this;

        // get the name of the language by the language id
        let locale = null;
        if (seoData.language_id) {
          const { data: { languages_by_pk: languageObject } } = await this.$apollo.query({
            query: GetLanguageById,
            variables: {
              languageId: seoData.language_id,
            },
          });
          if (languageObject) {
            locale = languageObject.handle;
          }
        }
        // use the same data like in the publish elements
        const pageObject = {
          ...seoData,
          ...pageData,
        };
        const dataToBeSendToTheServer = {
          ...pageObject,
          locale,
          tags: this.pageTags,
        };
        const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: 'updatePageSettings',
          data: {
            old_slug: oldSlug,
            parent_slug_path: `/${this.parentSlugPath}`,
            group_slug: this.groupSlug,
            group_domain: this.groupDomain,
            corporate_identity: this.corporateIdentity,
            ...dataToBeSendToTheServer,
          },
        });
        if (publishedDataWasSuccessfulSend) {
          // delete the tags and the lookup entries of the object
          if (this.pageData.tags) {
            delete this.pageData.tags;
          }
          if (this.seoData.lookup_entries) {
            delete this.seoData.lookup_entries;
          }
          pageObject.context_settings = this.context;
          // update the page tags
          this.updatePageTags();
          await this.$apollo.mutate({
            mutation: SetPageSettings,
            variables: {
              page_id: pageId,
              page: pageObject,
            },
            update: (cache) => {
              const cachedPageSettings = cache.readQuery({
                query: GetPageSettingsById,
                variables: { id: pageId },
              });
              const newPageSettingsForCache = {
                ...cachedPageSettings.pages_by_pk,
                ...pageObject,
              };
              cache.writeQuery({
                query: GetPageSettingsById,
                variables: { id: pageId },
                data: { pages_by_pk: newPageSettingsForCache },
              });
            },
          });
          this.resetCacheForHeaderButtons();
          // delete the cache for the page
          const domain = this.groupDomain;
          const parentSlugPath = (this.parentSlugPath === null) ? '' : this.parentSlugPath;
          let slug_path = (oldSlug === null) ? '' : `${parentSlugPath ? `${parentSlugPath}/` : ''}${oldSlug}`;
          slug_path = slug_path.substring(0, slug_path.lastIndexOf('/'));

          await this.$apollo.mutate({
            mutation: DeleteCacheFetchMagazine,
            variables: {
              domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
              slug_path,
            },
          });
          // if the slug has changed redirect to the new url
          if (oldSlug !== this.pageData.slug) {
            // build the new url by replacing the old slug with the new slug
            const path = this.$route.params.parametersOfUrl;
            const pos = path.lastIndexOf(oldSlug);
            const redirectPath = `/${path.substring(0, pos)}${this.pageData.slug}/page-settings`;
            // we need the timeout because the item_lookup table has to be updated
            setTimeout(() => {
              this.$router.push({ path: redirectPath });
            }, 1000);
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    updatePageTags() {
      if (equalsIgnoreOrder(this.pageTags, this.pageTagsOriginal)) return false;
      const addedElements = [];
      const removedElements = [];
      this.pageTags.forEach((tag) => {
        if (!this.pageTagsOriginal.includes(tag)) {
          addedElements.push(tag);
        }
      });
      this.pageTagsOriginal.forEach((tag) => {
        if (!this.pageTags.includes(tag)) {
          removedElements.push(tag);
        }
      });
      if (addedElements.length > 0) {
        // check if tag already existed in the database
        addedElements.forEach(async (addedElement) => {
          if (this.allTagsOfGroup.includes(addedElement)) {
            // tag is already in the db, add only the entry in the pages_tags
            const { tagId } = this.allTagsOfGroupWithId.find((item) => item.name === addedElement);
            await this.$apollo.mutate({
              mutation: InsertPageTag,
              variables: {
                pageId: this.currentItemObject.id,
                tagId,
              },
            });
          } else {
            // we have to add the entry in the tags table and the pages_tags
            // data.insert_tags.returning[0].tag_id
            const tagReturnObject = await this.$apollo.mutate({
              mutation: InsertTag,
              variables: {
                groupId: this.groupId,
                name: addedElement,
              },
            });
            // refetch the all tags query
            await this.$apollo.queries.allTagsOfGroup.refresh();
            const tagId = tagReturnObject.data.insert_tags.returning[0].tag_id;
            await this.$apollo.mutate({
              mutation: InsertPageTag,
              variables: {
                pageId: this.currentItemObject.id,
                tagId,
              },
            });
          }
        });
      }
      if (removedElements.length > 0) {
        removedElements.forEach(async (removedElement) => {
          // remove the element from the pages_tags table
          const { tagId } = this.allTagsOfGroupWithId.find((item) => item.name === removedElement);
          await this.$apollo.mutate({
            mutation: DeletePageTag,
            variables: {
              pageId: this.currentItemObject.id,
              tagId,
            },
          });
          const {
            data: {
              tags_by_pk,
            },
          } = await this.$apollo.queries.numberOfTagsUsedInGroup.refetch({
            tagId,
          });
          if (!this.hasMoreTagsAppearanceInGroup(tags_by_pk)) {
            await this.$apollo.mutate({
              mutation: DeleteTag,
              variables: {
                tagId,
              },
            });
          }
        });
      }

      return true;
    },
    hasMoreTagsAppearanceInGroup(tagsByTagId) {
      return !(tagsByTagId.pages_tags.length === 0 && tagsByTagId.projects_tags.length === 0);
    },
    updateSeoSettings(handle, seoData) {
      if (handle === 'language') {
        this.seoData.language_id = seoData.id;
      } else {
        this.seoData[handle] = seoData;
      }
      // check if seo settings have changed!!!
      let isSeoDataChanged = false;
      // eslint-disable-next-line array-callback-return
      Object.entries(this.seoDataOriginal).map((element) => {
        const theValue = element[1];
        const theKey = element[0];
        switch (theKey) {
          // exclude keys which are not relevant
          case '__typename':
          case 'page':
            break;
          case 'og_images':
            if (!isEqual(this.seoData[theKey], theValue)) {
              if (!theValue && !this.seoData[theKey]?.image) break;
              isSeoDataChanged = true;
            }
            break;
          default:
            if (theValue !== this.seoData[theKey]) {
              // we have to take this case because on the first time empty means theValue === null
              // and this.pageData[theKey] === ''
              if (!theValue && !this.seoData[theKey]) break;
              isSeoDataChanged = true;
            }
        }
      });
      this.pageSettingsChanged(isSeoDataChanged);
    },
    updatePageDescriptionSettings(handle, pageData) {
      if (handle === 'tags') {
        this.pageTags = pageData;
      } else {
        this.pageData[handle] = pageData;
      }
      // check if page settings have changed!!!
      let isDataChanged = false;
      if (handle === 'tags' && !equalsIgnoreOrder(pageData, this.pageTagsOriginal)) {
        isDataChanged = true;
      }
      // eslint-disable-next-line array-callback-return
      Object.entries(this.pageDataOriginal).map((element) => {
        const theValue = element[1];
        const theKey = element[0];
        switch (theKey) {
          // exclude keys which are not relevant
          case '__typename':
            break;
          case 'thumbnails':
            if (!isEqual(this.pageData[theKey], theValue)) {
              if (!theValue && !this.pageData[theKey]?.image) break;
              isDataChanged = true;
            }
            break;
          case 'keywords':
            if (!isEqual(this.pageData[theKey], theValue)) {
              if (!theValue && !this.pageData[theKey].length) break;
              isDataChanged = true;
            }
            break;
          default:
            if (theValue !== this.pageData[theKey]) {
              // we have to take this case because on the first time empty means theValue === null
              // and this.pageData[theKey] === ''
              if (!theValue && !this.pageData[theKey]) break;
              isDataChanged = true;
            }
        }
      });
      this.pageSettingsChanged(isDataChanged);
    },
    pageSettingsChanged(isDataChanged) {
      this.setDataChangedInCache({
        pageType: this.pageType,
        isDataChanged,
        isSubmittedButtonPressed: false,
        isCancelButtonPressed: false,
        isDataPublished: this.isPublished,
      });
    },
    resetSettings() {
      this.resetCacheForHeaderButtons();
      this.$apollo.queries.pageSettings.refresh();
    },
  },
  created() {
    const positonOfLastSlash = this.parametersOfUrl.lastIndexOf('/');
    this.parentSlugPath = (this.parametersOfUrl || positonOfLastSlash !== -1)
      ? this.parametersOfUrl.substring(0, positonOfLastSlash)
      : null;
    this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: 'page-settings',
      },
    });
    this.$apollo.mutate({
      mutation: SetRunBreadcrumbQueryInCache,
      variables: {
        runQuery: true,
      },
    });
    this.$apollo.mutate({
      mutation: SetShowHeaderInCache,
      variables: {
        show: true,
      },
    });
  },
  props: {
    parametersOfUrl: {
      type: String,
      default: null,
    },
  },
};
</script>
