import gql from 'graphql-tag';

export default gql`
mutation updatePageSettings(
  $page_id: Int!,
  $page: pages_set_input!
) {
  update_pages(
    where: {page_id: {_eq: $page_id}}
    _set: $page
  ) {
    affected_rows
  }
}
`;
