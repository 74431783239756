import gql from 'graphql-tag';

export default gql`
query getPersonalizedVariableSetsItems($itemPath: String!, $addValues: Boolean) {
  getPersonalizedVariableSetsByPath(object: { item_path: $itemPath, add_values: $addValues }) {
    personalized_variable_set_id
    name
    schema
    values
  }
}
`;
