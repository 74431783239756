import gql from 'graphql-tag';

export default gql`
query getPageSettingsById($id: Int!) {
  pages_by_pk(page_id:$id) {
    page_id
    listed
    name
    parent_folder_id
    parent_project_id
    description
    thumbnails
    keywords
    published
    language_id
    group_id
    searchable
    item_path
    parent_folder {
      corporate_identity
    }
    parent_project {
      corporate_identity
    }
    latest_page_version {
      display_data
      version_number
    }
    pages_tags {
      tag {
        name
      }
    }
    og_title
    og_description
    og_images
    slug
    canonical_url
    is_shared_template
    hreflang
    context_settings
    lookup_entries {
      group {
        domain
        slug
      }
    }
  }
}
`;
